.embla {
  position: relative;
  padding: 10px;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.embla__gradient {
  pointer-events: none;
  background: transparent
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.8) 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 80px;
  z-index: 2;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  padding-left: 10px;
  position: relative;
  flex: 0 0 100%; /* Adapt slide size to its content */
  max-width: 100%; /* Prevent from growing larger than viewport */
}

.embla__slide--footer {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.footer__left,
.footer__right {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__btn {
  padding: 8px 20px !important;
  text-transform: none !important;
}

.footer__blocked {
  width: auto;
  padding: 8px 20px;
  border-radius: 999px;
  background: #eb2c2c 0% 0% no-repeat padding-box;
  opacity: 0.6;
  border: none;
  font-size: 14px;
  color: white;
  box-sizing: border-box;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  /* width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none; */
  transform: translate(-50%, -50%);
  height: 100%;
}

.embla--thumb {
  padding-top: 0;
  margin-top: -12px;
}

.embla--thumb .embla__viewport {
  padding: 20px 0;
}

.embla__container--thumb {
  cursor: default;
  margin-left: -8px;
}

.embla__slide--thumb {
  padding-left: 8px;
  min-width: 12%;
  transition: transform 0.3s ease-in-out;
}

.embla__slide__inner--thumb {
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  height: 50px;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: block;
  overflow: hidden;
}

.embla__slide__thumbnail {
  position: absolute;
  opacity: 1;
  top: 0;
  bottom: 0;
  left: -10000%;
  right: -10000%;
  margin: auto;
  min-width: 1000%;
  min-height: 1000%;
  max-width: none;
  transform: scale(0.1);
  transition: opacity 0.2s;
}

.embla__slide--thumb.is-selected {
  transform: scale(1.4);
  margin: 0 10px;
}
