.embla-mini {
  position: relative;
  background-color: #322941;
  width: 345px;
  height: 345px;
  margin-left: auto;
  margin-right: auto;
}

.embla__gradient-mini {
  pointer-events: none;
  background: transparent linear-gradient(180deg, #15131e 0%, #15131e00 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.9;
  position: absolute;
  top: 0px;
  left: 0;
  right: -1px;
  height: 30px;
  z-index: 2;
}

.embla-mini__viewport {
  overflow: hidden;
  width: 100%;
}

.embla-mini__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla-mini__viewport.is-dragging {
  cursor: grabbing;
}

.embla-mini__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla-mini__slide {
  position: relative;
  min-width: 100%;
}

.embla-mini__slide__inner {
  position: relative;
  overflow: hidden;
  height: 190px;
}

.embla-mini__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  /* width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none; */
  transform: translate(-50%, -50%);
  height: 100%;
}

.embla-mini__dots {
  display: flex;
  list-style: none;
  justify-content: center;

  position: absolute;
  top: 4px;
  width: 100%;
}

.embla-mini__dot {
  background-color: transparent;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  height: 2px;
  margin-right: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
}

.embla-mini__dot:after {
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 4px;
  border-radius: 1px;
  content: "";
}

.embla-mini__dot.is-selected:after {
  background-color: rgb(255, 255, 255);
  opacity: 1;
}
